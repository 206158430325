<template>
  <div>
    <div>
      <h2 class=" ">
        Credits -<span class="text-primary"> {{ orgName }}</span>
      </h2>
    </div>
    <b-tabs>
      <b-tab title="Credit History">
        <b-row>
          <b-col cols="12">
            <b-card class="border-primary">
              <b-row>
                <!-- Asset Info: Left col -->
                <!-- {{ wallet }} -->
                <!-- {{ items }} -->
                <b-col
                  cols="12"
                  xl="6"
                  class="d-flex justify-content-between flex-column"
                >
                  <b-card no-body>
                    <b-card-header
                      class="d-flex justify-content-between align-items-center pt-75 pb-25"
                    >
                      <!-- <h4 class="mb-0">Credit Balance</h4> -->
                      <b-badge
                        variant="light-primary"
                        v-if="wallet.is_unlimited == true"
                      >
                        UNLIMITED
                      </b-badge>
                      <!-- <b-button
                        v-if="
                          wallet.is_unlimited == true &&
                          this.$store.state.app.user.permissions.includes(
                            'credit.update_limit_creditwallet'
                          )
                        "
                        variant="outline-danger"
                        size="sm"
                        @click="updateCreditLimit()"
                      >
                        Change Credit Limit
                      </b-button> -->
                    </b-card-header>

                    <b-card-body>
                      <ul class="list-unstyled my-1">
                        <li v-if="!wallet.is_unlimited == true">
                          <span class="align-middle"
                            >Credit Balance:
                            <b-badge variant="success" class="ml-1">{{
                              wallet.balance_amount
                            }}</b-badge></span
                          >
                        </li>
                        <!-- <li>
                    <span class="align-middle">Aditional credit: </span>
                  </li> -->
                        <li class="my-25" v-if="wallet.is_unlimited == true">
                          <span class="align-middle">Unlimited Credit: </span>
                          <b-badge variant="success">YES</b-badge>
                        </li>
                        <!-- <li class="my-25">
                    <span class="align-middle">Unlimited Credit: </span>
                    <b-badge variant="success">YES</b-badge>
                  </li> -->
                        <li
                          class="my-2"
                          v-if="
                            this.$store.state.app.user.permissions.includes(
                              'credit.add_creditwallet'
                            )
                          "
                        >
                          <b-button
                            variant="outline-success"
                            size="sm"
                            @click="openAddCredit()"
                          >
                            + Add Credit
                          </b-button>
                          <div class="my-1" v-if="openCredit">
                            <b-form-group
                              label="Amount"
                              label-for="credit_amount"
                            >
                              <b-input-group class="input-group-merge w-50">
                                <b-form-input
                                  type="number"
                                  id="credit_amount"
                                  v-model.number="creditAmount"
                                  onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                                />

                                <b-input-group-append>
                                  <b-button
                                    variant="primary"
                                    size="sm"
                                    @click="addCredit()"
                                    :disabled="isDisable"
                                    ><feather-icon
                                      icon="PlusIcon"
                                      class="text-white"
                                    />Add</b-button
                                  >
                                </b-input-group-append>
                              </b-input-group>
                              <!-- {{ typeof creditAmount }} -->
                            </b-form-group>
                            <!-- <b-form-input type="number" class="w-50"> </b-form-input> -->
                          </div>
                        </li>
                      </ul>
                      <!-- <b-button variant="primary" block @click="gotoAssests()">
                  Back to Assets
                </b-button> -->
                    </b-card-body>
                  </b-card>
                </b-col>

                <!-- Right Col: Ports Info -->
                <b-col cols="12" xl="6"> </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="12">
            <b-card class="my-1" title="Transactions">
              <!-- {{ items }} -->
              <b-table
                id="tranTable"
                :fields="fields"
                :items="items"
                :per-page="perPage"
                responsive
                hover
              >
                <template #cell(index)="data">
                  {{ data.index + 1 + perPage * (currentPage - 1) }}
                </template>
                <template #cell(created_at)="data">
                  <div
                    class="d-flex justify-content-between"
                    style="white-space: nowrap; overflow: hidden"
                  >
                    <span
                      style="text-overflow: ellipsis"
                      class="text-capitalize"
                      >{{ data.item.created_at | formatdate }}</span
                    >
                  </div>
                </template>
                <template #cell(transaction_type)="data">
                  <a @click="showLogSidebar(data.item.id)">
                    <b-badge
                      variant="success"
                      v-if="data.item.transaction_type == 'credit'"
                      >Credit</b-badge
                    >
                    <b-badge variant="danger" v-else>Debit</b-badge>
                  </a>
                </template>
                <template #cell(status)="data">
                  <b-badge variant="warning" v-if="data.item.status == 1"
                    >In Progress</b-badge
                  >
                  <b-badge variant="success" v-else-if="data.item.status == 2"
                    >Completed</b-badge
                  >
                  <b-badge variant="danger" v-else>Failed</b-badge>
                </template>
                <template #cell(agent_type)="data">
                  <a
                    class="text-primary"
                    @click="
                      showSidebar(
                        data,
                        data.item.id,
                        data.item.scan_id,
                        data.item.scan_type
                      )
                    "
                  >
                    {{ data.item.agent_type }}
                  </a>
                </template>
                <template #cell(logDetails)="data">
                  <b-button
                    size="sm"
                    variant="outline-primary"
                    @click="showLogSidebar(data.item.id)"
                    >Details</b-button
                  >
                </template>
              </b-table>

              <b-pagination-nav
                v-model="currentPage"
                :numberOfPages="total"
                :total-rows="rows"
                :link-gen="linkGen"
                :per-page="perPage"
                :align="pagination_pos"
                aria-controls="LogTable"
                use-router
              />
            </b-card>
          </b-col>
          <b-sidebar
            id="sidebar-details"
            sidebar-class="sidebar-lg"
            bg-variant="white"
            shadow
            backdrop
            no-header
            right
            width="50%"
          >
            <ScanDetails
              :scan_details="scanDetails"
              :transactionId="transactionId"
              :scan_id="scan_id"
              :scan_type="scan_type"
            />
            <!-- {{ scanDetails }} -->
          </b-sidebar>
          <b-sidebar
            id="sidebar-log"
            sidebar-class="sidebar-lg"
            bg-variant="white"
            shadow
            backdrop
            no-header
            right
            width="50%"
          >
            <LogDetails :transactionId="transactionId" />
          </b-sidebar>
        </b-row>
      </b-tab>
      <b-tab
        v-if="
          this.$store.state.app.user.permissions.includes(
            'credit.list_organizationagentcredit'
          )
        "
        title="Agent Configuration"
      >
        <b-card>
          <div
            class="my-1"
            v-if="
              this.$store.state.app.user.is_staff &&
              this.$store.state.app.user.permissions.includes(
                'credit.add_organizationagentcredit'
              )
            "
          >
            <b-button variant="success" size="sm" @click="gotoAgent(org_id)">
              Add
            </b-button>
          </div>
          <!-- {{ agentItems }} -->
          <b-table
            id="agentTable"
            :fields="agentfields"
            :items="agentItems"
            responsive
            hover
          >
            <template #cell(index)="data">
              {{ data.index + 1 + agentperPage * (agentcurrentPage - 1) }}
            </template>
            <template #cell(is_unlimited)="data">
              <b-badge variant="success" v-if="data.item.is_unlimited == true"
                >YES</b-badge
              >
              <b-badge variant="danger" v-else>NO</b-badge>
            </template>
            <template #cell(actions)="data">
              <b-dropdown
                v-if="isStaff"
                size="sm"
                class="my-1"
                variant="outline-primary"
                text="Actions"
              >
                <b-dropdown-item
                  v-if="isStaff && editPermission"
                  @click="editAgent(data.item.id)"
                  >Edit</b-dropdown-item
                >
                <b-dropdown-divider />
                <b-dropdown-item
                  v-if="isStaff && deletePermission"
                  @click="gotoDelete(data.item.id, data.item.agent_type_name)"
                  >Delete</b-dropdown-item
                >
              </b-dropdown>
              <!-- <b-button variant="warning" size="sm" @click="editAgent(data.item.id)"></b-button> -->
            </template>
          </b-table>
          <b-pagination-nav
            v-model="agentcurrentPage"
            :numberOfPages="agenttotal"
            :total-rows="agentrows"
            :link-gen="agentlinkGen"
            :per-page="agentperPage"
            :align="pagination_pos"
            aria-controls="AgentTable"
            use-router
          />
        </b-card>
      </b-tab>
    </b-tabs>
    <b-modal
      id="modal-danger"
      hide-footer
      modal-class="modal-danger"
      centered
      title="Delete.. !"
      v-model="openDeleteModal"
      size="lg"
    >
      <b-card-text>
        Do you really want to delete
        <span class="text-primary">"{{ agent_type_name }}"</span> ?
      </b-card-text>
      <div class="d-flex justify-content-end">
        <b-button @click="deleteAgent()" variant="danger" size="sm">
          Delete
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BModal,
  BPagination,
  BPaginationNav,
  BButton,
  BRow,
  BCol,
  BBadge,
  BCard,
  BCardHeader,
  BCardBody,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BSidebar,
  BTabs,
  BTab,
  BInputGroupAppend,
  BInputGroup,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ScanDetails from "./creditDetails.vue";
import LogDetails from "./logDetails.vue";
import moment from "moment";
export default {
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BRow,
    BCol,
    BBadge,
    BCard,
    BCardHeader,
    BCardBody,
    BModal,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BSidebar,
    BTabs,
    BTab,
    BInputGroupAppend,
    BInputGroup,
    ScanDetails,
    LogDetails,
  },
  data() {
    return {
      pagination_pos: "center",
      perPage: 10,
      currentPage: 1,
      rows: 0,
      total: 1,
      wallet: [],
      items: [],
      fields: [
        { key: "index", label: "#" },
        { key: "transaction_amount", label: "Credit" },
        { key: "balance_amount", label: "Balance Amount" },
        { key: "status", label: "Transaction Status" },
        { key: "transaction_type", label: "Transaction Type" },
        { key: "agent_type", label: "Scan Type" },
        { key: "created_at", label: "Created At" },
        { key: "created_by", label: "Created By" },
        // { key: "logDetails", label: "Action" },
      ],
      // org_id: !this.$store.state.app.user.is_staff
      //   ? this.$store.state.app.user.profile.organization
      //   : this.$route.params.id,
      org_id: !this.$store.state.app.user.is_staff
        ? this.$store.state.app.organizationId
        : this.$route.params.id,
      org_ref_id: this.$route.params.ref_id,
      isDisable: false,
      openCredit: false,
      scanDetails: [],
      transactionId: null,
      scan_id: null,
      scan_type: null,
      walletId: null,

      // Agent Configuration
      creditAmount: null,
      agentItems: [],
      agentfields: [
        { key: "index", label: "#" },
        { key: "agent_type_name", label: "Agent" },
        { key: "org_detail.org_name", label: "Organization" },
        { key: "is_unlimited", label: "Unlimited" },
        { key: "credit_per_scan", label: "Credit Per Scan" },
        { key: "actions", label: "Action" },
      ],
      agentrows: 0,
      agenttotal: 1,
      agentperPage: 10,
      agentcurrentPage: 1,
      agent_id: "",
      agent_type_name: "",
      openDeleteModal: false,
      editPermission: false,
      deletePermission: false,
      orgName: "",
    };
  },
  watch: {
    currentPage: function (newVal, oldVal) {
      this.loadTransaction(false);
    },
    agentcurrentPage: function (newVal, oldVal) {
      this.agentConfiload(false);
    },
  },
  created() {
    this.load();
    // this.loadTransaction();
    this.agentConfiload();
    this.isStaff = this.$store.state.app.user.is_staff;
    this.editPermission = this.$store.state.app.user.permissions.includes(
      "credit.change_organizationagentcredit"
    );
    this.deletePermission = this.$store.state.app.user.permissions.includes(
      "credit.delete_organizationagentcredit"
    );
  },
  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MMM-YY hh:mm A");
      }
    },
  },
  methods: {
    linkGen: function (pageNum) {
      return this.$router.currentRoute.path + "?page=" + pageNum;
    },
    agentlinkGen: function (pageNum) {
      return this.$router.currentRoute.path + "?page=" + pageNum;
    },
    openAddCredit() {
      this.openCredit = true;
    },
    updateCreditLimit() {
      this.isDisable = true;
      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },

        url:
          process.env.VUE_APP_BASEURL +
          "organization/organization/" +
          this.org_id +
          "/update-credit-limit/",
      };
      var self = this;
      this.$http(options).then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });

          this.isDisable = false;
          this.load();
        }
      });
    },
    addCredit() {
      console.log("success");
      this.isDisable = true;
      let data = {
        transaction_amount: this.creditAmount,
      };
      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        data: data,
        url:
          process.env.VUE_APP_BASEURL +
          "organization/organization/" +
          this.org_id +
          "/add-credit/",
      };
      var self = this;
      this.$http(options).then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Added Credit Amount Successfully",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.openCredit = false;
          this.creditAmount = null;
          this.isDisable = false;
          this.load();
        }
      });
    },
    load: function () {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "organization/organization/" +
          this.org_id,
      };
      this.$http(options).then((res) => {
        this.wallet = res.data.wallet;
        this.walletId = res.data.wallet.id;
        this.orgName = res.data.org_name;
        this.loadTransaction();
        // this.items = res.data.wallet.transactions;
      });
    },
    loadTransaction: function (reset = true) {
      if (this.$route.query.page) this.currentPage = this.$route.query.page;

      if (reset) this.currentPage = 1;
      let url = url;
      if (this.$store.state.app.user.is_staff) {
        url =
          process.env.VUE_APP_BASEURL +
          "credit/credit-transaction?wallet_id=" +
          this.walletId +
          "&page=" +
          this.currentPage;
      } else {
        url =
          process.env.VUE_APP_BASEURL +
          "credit/credit-transaction" +
          "?page=" +
          this.currentPage;
      }
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      this.$http(options).then((res) => {
        this.items = res.data.results;
        this.rows = res.data.length;
        this.total = Math.ceil(res.data.count / this.perPage);
      });
    },
    showLogSidebar: function (id) {
      this.transactionId = id;
      this.$root.$emit("bv::toggle::collapse", "sidebar-log");
    },
    showSidebar: function (data, id, scan_id, scan_type) {
      this.scanDetails = data.item.scan_details;
      this.transactionId = id;
      this.scan_id = scan_id;
      this.scan_type = scan_type;
      console.log("dataaaa", data);
      this.$root.$emit("bv::toggle::collapse", "sidebar-details");
    },
    agentConfiload: function (reset = true) {
      if (this.$route.query.page)
        this.agentcurrentPage = this.$route.query.page;
      let url = process.env.VUE_APP_BASEURL + "credit/org-agent-credit/";
      // "?page=" + this.agentcurrentPage;
      if (this.$store.state.app.user.is_staff) {
        url = url + "?org_id=" + this.org_ref_id;
      }
      if (reset) this.agentcurrentPage = 1;
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      this.$http(options).then((res) => {
        console.log(res.data, "agent configuration ffff");
        this.agentItems = res.data.results;
        this.agentrows = res.data.length;
        this.agenttotal = Math.ceil(res.data.count / this.agentperPage);
      });
    },
    gotoDelete(id, agent_type_name) {
      this.openDeleteModal = true;
      this.agent_id = id;
      this.agent_type_name = agent_type_name;
    },
    gotoAgent(id) {
      this.$router.push({ name: "Agent Configuration", params: { id: id } });
    },
    deleteAgent() {
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "credit/org-agent-credit/" +
          this.agent_id +
          "/",
      };
      this.$http(options).then((res) => {
        console.log(res);
        this.openDeleteModal = false;
        this.agentConfiload();
      });
    },
    editAgent(id) {
      this.$router.push({
        name: "Edit Agent Configuration",
        params: { id: id },
      });
    },
  },
};
</script>
<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
