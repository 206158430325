<template>
  <div>
    <b-card-code title="Scan Details">
      <!-- {{ scan_type }} -->
      <div class="w-100">
        <b-card align="center" class="bg-dark-blue h-100">
          <div class="row">
            <div class="col">
              <b-list-group class="list-group-circle text-left bg-transparent">
                <b-list-group-item
                  v-if="scan_details.asset_name != ''"
                  class="bg-transparent"
                >
                  <b>Asset Name :</b>
                  <span>
                    {{ scan_details.asset_name }}
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  v-if="scan_details.org_name != ''"
                  class="bg-transparent"
                >
                  <b>Organization Name :</b>
                  <span>
                    {{ scan_details.org_name }}
                  </span>
                </b-list-group-item>
                <b-list-group-item class="bg-transparent">
                  <b>Agent Name :</b>
                  <span class=""> {{ scan_details.agent_type_name }}</span>
                </b-list-group-item>
                <b-list-group-item class="bg-transparent">
                  <b>Created By :</b>
                  <span class=""> {{ scan_details.scan_created_by }}</span>
                </b-list-group-item>
                <b-list-group-item
                  v-if="scan_details.asset_group != ''"
                  class="bg-transparent"
                >
                  <b>Asset Group :</b>
                  <span class=""> {{ scan_details.asset_group }}</span>
                </b-list-group-item>
                <b-list-group-item
                  v-if="scan_details.domain != ''"
                  class="bg-transparent"
                >
                  <b>Domain :</b>
                  <span class=""> {{ scan_details.domain }}</span>
                </b-list-group-item>
                <b-list-group-item class="bg-transparent">
                  <b>Scan ID :</b>
                  <span class=""> {{ scan_id }}</span>
                </b-list-group-item>
                <b-list-group-item class="bg-transparent">
                  <b>Scan Type :</b>
                  <!-- <span class="" > {{ scan_type }}</span> -->
                  <span class="" v-if="scan_type == 'assetscan'">
                    Asset Scan</span
                  >
                  <span class="" v-else-if="scan_type == 'assetdiscoveryscan'">
                    Asset Discovery Scan</span
                  >
                  <span class="" v-else> {{ scan_type }}</span>
                </b-list-group-item>
                <b-list-group-item class="bg-transparent">
                  <b>Created At :</b>
                  <span class="">
                    {{ scan_details.created_at | formatdate }}
                    </span
                  >
                </b-list-group-item>
                <b-list-group-item class="bg-transparent">
                  <b>Scan Status :</b>
                  <span class="ml-1">
                    <b-badge
                      variant="primary"
                      v-if="scan_details.scan_status == 0"
                      >Scheduled</b-badge
                    >
                    <b-badge
                      variant="warning"
                      v-else-if="scan_details.scan_status == 1"
                      >In Progress</b-badge
                    >
                    <b-badge
                      variant="success"
                      v-else-if="scan_details.scan_status == 2"
                      >Completed</b-badge
                    >
                    <b-badge variant="danger" v-else>Failed</b-badge>
                  </span>
                </b-list-group-item>
                <b-list-group-item class="bg-transparent">
                  <b>Last Run At :</b>
                  <span class="">
                    {{ scan_details.last_run_at | formatdate }}</span
                  >
                </b-list-group-item>
                <b-list-group-item class="bg-transparent">
                  <b>Error :</b>
                  <span class=""> {{ scan_details.errors }}</span>
                </b-list-group-item>
                <b-list-group-item class="bg-transparent">
                  <b>Details : </b>
                  <span class="" v-html="items[0].log_message"></span>
                </b-list-group-item>
              </b-list-group>
            </div></div
        ></b-card>
      </div>
    </b-card-code>
  </div>
</template>



<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BModal,
  BPagination,
  BPaginationNav,
  BButton,
  BRow,
  BCol,
  BBadge,
  BCard,
  BCardHeader,
  BCardBody,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BSidebar,
  VBToggle,
  BSpinner,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import moment from "moment";
export default {
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BRow,
    BCol,
    BBadge,
    BCard,
    BCardHeader,
    BCardBody,
    BModal,
    BForm,
    BSpinner,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    ValidationProvider,
    ValidationObserver,
    BSidebar,
    BListGroup,
    BListGroupItem,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    scan_details: {
      type: Array,
      required: true,
    },
    transactionId: {
      type: Number,
      required: true,
    },
    scan_id: {
      type: Number,
      required: true,
    },
    scan_type: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      userId: localStorage.getItem("userid"),
      items: [],
      scan_details: [],
      transactionId: null,
    };
  },
  watch: {
    transactionId() {
      this.load();
    },
  },
  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MMM-YY hh:mm A");
      }
    },
  },
  methods: {
    load: function () {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "logs/v1/logs/transaction-log?transaction_id=" +
          this.transactionId,
      };
      this.$http(options).then((res) => {
        this.items = res.data.results;
      });
    },
  },
};
</script>

<style lang="css" >
.bg-dark-blue {
  /* background-color: #171153 !important; */
  background-color: #1a457b1f !important;
  /* background-color: #1e176c !important; */
}

[dir] .dark-layout .bg-dark-blue {
  background-color: #04002f !important;
  color: #fff;
}
[dir].dark-layout .list-group-item {
  padding: 0.75rem 1.25rem;
  background-color: #283046 !important;
  border: 1px solid rgb(68 101 129) !important;
}
</style>
