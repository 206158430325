<template>
  <div>
    <b-card-code title="Transaction Message">
      <b-table
        responsive
        id="LogTable"
        :fields="fields"
        :items="items"
        ref="table"
        hover
      >
        <template #cell(log_message)="data">
          <p v-html="data.item.log_message"></p>
        </template>
      </b-table>
    </b-card-code>
  </div>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BModal,
  BPagination,
  BPaginationNav,
  BButton,
  BRow,
  BCol,
  BBadge,
  BCard,
  BCardHeader,
  BCardBody,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BSidebar,
  VBToggle,
  BSpinner,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import moment from "moment";
export default {
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BRow,
    BCol,
    BBadge,
    BCard,
    BCardHeader,
    BCardBody,
    BModal,
    BForm,
    BSpinner,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    ValidationProvider,
    ValidationObserver,
    BSidebar,
    BListGroup,
    BListGroupItem,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    transactionId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      items: [],
      fields: [{ key: "log_message", label: "Message" }],
      transactionId: null,
    };
  },

  watch: {
    transactionId() {
      this.load();
    },
  },

  methods: {
    load: function () {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "logs/v1/logs/transaction-log?transaction_id=" +
          this.transactionId,
      };
      this.$http(options).then((res) => {
        this.items = res.data.results;
      });
    },
  },
};
</script>

<style lang="css" >
.bg-dark-blue {
  /* background-color: #171153 !important; */
  background-color: #1a457b1f !important;
  /* background-color: #1e176c !important; */
}

[dir] .dark-layout .bg-dark-blue {
  background-color: #04002f !important;
  color: #fff;
}
[dir].dark-layout .list-group-item {
  padding: 0.75rem 1.25rem;
  background-color: #283046 !important;
  border: 1px solid rgb(68 101 129) !important;
}
</style>
